import React, { useState } from "react"

// components
import SEO from "../components/SEO"
import Hero from "../components/Hero"
import Subscribe from "../components/Subscribe"
import styles from "../styles/modules/static-pages.module.scss"
import FAQs from "../components/FAQs"

import Modal, { ModalType } from "../components/Modal"

const questions = [
  {
    question: (
      <>
        I just want to purchase one or two cards. What’s the best way to do
        that?
      </>
    ),
    answer: (
      <>Click the “send gift card” button above and enter your information.</>
    ),
  },
  {
    question: <>I want to purchase multiple cards.</>,
    answer: (
      <>
        Visit our{" "}
        <a href="https://nbshare.org/bulk-order/">bulk Giving Cards page</a> for
        instructions and FAQs on a bulk purchase.
      </>
    ),
  },
  {
    question: (
      <>
        Will I email the recipients their cards, or will NeighborShare email the
        cards?
      </>
    ),
    answer: (
      <>
        That's your choice! If you purchase individually, you can enter your own
        email for delivery or the recipient’s email for immediate delivery. If
        you use the{" "}
        <a href="https://nbshare.org/bulk-order/">bulk send option</a> (even if
        it’s not a bulk order), we can schedule it to send it automatically at a
        day and time of your choice. Others who use the bulk purchase option
        still opt to have all of the emails delivered to them so they can
        forward them on at a time of their choosing with a personal cover note.
      </>
    ),
  },
  {
    question: (
      <>
        I would like to print the Giving Card and wrap it up. How do I do that?
      </>
    ),
    answer: (
      <>
        When you purchase the Giving Card, simply enter your own email address
        so the card is sent directly to you.{" "}
        <a
          href="https://storage.googleapis.com/ns-assets-pd/reports/Screen%20Shot%202023-10-25%20at%2012.03.06%20PM.png"
          target="_blank"
          rel="noopener noreferrer"
        >
          Click here for a preview of the email.
        </a>
      </>
    ),
  },
  {
    question: <>I have a question not listed here. </>,
    answer: (
      <>
        No problem! Email us at{" "}
        <a href={"mailto:info@nbshare.org"}>info@nbshare.org</a>.
      </>
    ),
  },
]

const Home = () => {
  const carouselInfo = {
    title: "Give the gift of giving",
    subtitle:
      "Purchase a NeighborShare Giving Card for a client, colleague, or loved one",
    hideDonate: true,
  }

  const [giftCardOpen, setGiftCardOpen] = useState(false)
  return (
    <>
      <SEO title="Giving Cards" />
      <Hero carouselInfo={carouselInfo} />
      <div className={`wrapper wrapper--left ${styles.aboutUs}`}>
        <h2 className={`x-large-body-copy ${styles.aboutUsTitle}`}>
          NeighborShare Giving Cards
        </h2>
        <p className={`body-copy ${styles.mediumParagraph}`}>
          NeighborShare’s Giving Cards are a unique and meaningful gift option
          for loved ones, employees, colleagues, or clients. Pre-load
          (electronic) Giving Cards with a donation amount so your recipient can
          give to needs close to their heart.
        </p>
        <p className={`body-copy ${styles.mediumParagraph}`}>
          Here are some ways neighbors have put the Giving Cards to use in the
          past:
        </p>
        <p className={`body-copy ${styles.mediumParagraph}`}>
          <ul>
            <li>
              A unique gift for that person who has everything’s big birthday
            </li>
            <li>A tech-enabled way to introduce your kids to giving</li>
            <li>End-of-year client gifts</li>
            <li>Stocking stuffers</li>
            <li>Party favors</li>
          </ul>
        </p>
        <p className={`body-copy ${styles.mediumParagraph}`}>
          <button
            className={styles.donateBtn}
            onClick={() => {
              setGiftCardOpen(true)
            }}
          >
            Send an individual Giving Card
          </button>
        </p>
        <p className={`body-copy ${styles.mediumParagraph}`}>
          <a href="https://nbshare.org/bulk-order/">
            For bulk Giving Card purchases, click here.
          </a>
        </p>
      </div>
      <FAQs questions={questions} />
      <Subscribe />
      <Modal
        modalType={ModalType.GiveGift}
        modalOpen={giftCardOpen}
        handleModalClose={() => {
          setGiftCardOpen(false)
        }}
      />
    </>
  )
}

export default Home
